










import { Vue, Component } from 'vue-property-decorator';
@Component
export default class SKClose extends Vue {
  public handleClick() {
    if (this.$route.name === 'search') {
      const from = this.$store.getters.searchOpenedFrom;
      this.$router.push(from || { name: 'home' });
      this.$store.commit('searchOpenedFrom', null);
      return;
    }
    this.$router.back();
  }
}
