

















































import SKBodyFrontMale from '@/components/sk-body/SK-BodyFrontMale.vue';
import SKBodyBackMale from '@/components/sk-body/SK-BodyBackMale.vue';
import SKBodyFrontFemale from '@/components/sk-body/SK-BodyFrontFemale.vue';
import SKBodyBackFemale from '@/components/sk-body/SK-BodyBackFemale.vue';

import { Vue, Component } from 'vue-property-decorator';
import SKClose from '@/components/SK-Close.vue';

@Component({
  components: {
    SKBodyFrontMale,
    SKBodyBackMale,
    SKBodyFrontFemale,
    SKBodyBackFemale,
    SKClose
  }
})
export default class Search extends Vue {
  public front = true;
  public category = 0;

  get male(): boolean {
    return this.category === 0;
  }

  public selected(selected: string): void {
    const sex = this.male ? 'men' : 'women';
    this.$router.push({
      name: 'search-results',
      params: { sex: sex, muscle: selected }
    });
  }
}
